/* 
    header 
*/
.header {
  width: 100vw;
  z-index: 1;
  background-color: #fff;
  box-shadow: 0px 0px 10px #333;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 50px;
  padding: 10px 3rem;

  transform: translateY(-70px);
  transition: 0.5s;
  position: fixed;
  top: 0;
  left: 0;
}
.visivle {
  transform: translateY(0);
}
.header .sitetitle {
  height: 50px;
  font-size: 40px;
  font-weight: bolder;
  color: #cd5c5c;
  cursor: pointer;
}
.header .sitetitle p {
  color: #cd5c5c;
}
.linktag {
  cursor: pointer;
  color: #cd5c5c;
}
.header nav {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  font-weight: bold;
  line-height: 12px;
}
.header nav li {
  margin-left: 25px;
}
.header .linktag:hover {
  border-bottom: 1.8px solid #858a8f;
}

/* 
    footer 
*/
.footer .end {
  display: flex;
  background-color: #cd5c5c;
  color: #fff;
  padding-top: 50px;
  padding-bottom: 50px;
}
.footer .sitetitle {
  font-size: 40px;
  font-weight: bold;
  margin-left: 10%;
}
.footer .sitetitle p {
  color: #fff;
}
.footer .endnav {
  width: 60%;
  margin-top: 20px;
  margin-right: 10%;
}
.footer .endnav ul {
  display: flex;
  justify-content: right;
  font-weight: bold;
  line-height: 12px;
}
.footer .endnav ul li {
  margin-left: 10px;
}
.footer .linktag {
  cursor: pointer;
  color: #fff;
}
.footer .linktag:hover {
  text-shadow: 1px 1px 5px #fff;
}
.copywrite {
  width: 100vw;
  height: 2rem;
  font-weight: 900;
  letter-spacing: 2px;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-width: 670px) {
  .header .ham nav li {
    margin-left: 10px;
  }

  .footer .end .sitetitle {
    display: flex;
    align-items: center;
  }
  .footer .endnav {
    margin-top: 0;
  }
  .footer .endnav ul {
    flex-direction: column;
    text-align: right;
  }
  .footer .endnav ul li {
    padding: 0.3rem 0;
  }
}

@media (max-width: 530px) {
  .header {
    flex-direction: column;
    transform: translateY(-95px);
  }
  .visivle {
    transform: translateY(0);
  }
  .header nav {
    align-items: center;
  }
  .header nav li {
    margin: 0 20px;
  }
  .sitetitle {
    width: 100%;
    text-align: center;
  }
  .ham {
    height: 1.5rem;
  }
}

/* @media (max-width: 450px) {
  .header {
    flex-direction: column;
    transform: translateY(-95px);
  }
  .header nav {
    align-items: center;
  }
  .header nav li {
    margin: 0 20px;
  }
  .sitetitle {
    width: 100%;
    text-align: center;
  }
  .ham {
    height: 1.5rem;
  }
} */
