.food-haikei {
  /* background-color: #f4f1ed; */
  /* background-color: #F8F8FF; */
  background-color: #f0f8ff7c;
  width: 100vw;
}
.food {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 5rem 3rem;
}
.food h2 {
  font-size: 40px;
  padding-bottom: 1rem;
  letter-spacing: 3px;
  color: #778899;
}

.food ul {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  padding: 0 1rem;
}
.food a {
  color: #000;
}
.food a:hover {
  opacity: 0.3;
  color: #fff;
  transition: all 0.3s;
}
.food img {
  margin: 5px;
  border: 1px solid #696969;
  border-radius: 10px;
  box-shadow: 1px 2px 3px#696969;
  width: 250px;
  height: 180px;
}
.food p {
  margin-bottom: 5px;
  text-align: center;
}

@media (max-width: 450px) {
  .food {
    padding: 5rem 0;
  }
  .food h2 {
    padding-left: 3rem;
  }
}
