.map-container {
  margin: 0 auto;
}

.info-show {
  display: block;
}
.no-info {
  display: none;
}
.marker-info {
  font-size: 10px;
}

.gm-ui-hover-effect {
  display: none;
}

.info-window {
  padding: 0.5rem;
}
.rate {
  display: flex;
  justify-content: space-evenly;
}
