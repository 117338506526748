.food-page {
  height: 100vh;
}

.food-page .header {
  display: flex;
  transform: translateY(0px);
}
.food-page .header nav {
  display: none;
}

.food-page-main {
  margin-top: 100px;
}

.food-title {
  display: flex;
  justify-content: center;
  letter-spacing: 1px;
  margin: 0.3rem 0;
}

.memo {
  text-align: center;
  margin: 1rem 0;
  padding: 0.3rem 0;
  letter-spacing: 1px;
  background-color: rgba(241, 241, 63, 0.5);
}

.title-container {
  margin: 1rem 0;
}
.title-border {
  width: 7rem;
  height: 3px;
  background-color: rgba(100, 148, 237, 0.6);
  margin: 0 auto;
}

.shops-container {
  text-align: center;
  margin: 2rem 0;
}
.foodpage-h2 {
  text-align: center;
  margin: 0.3rem 0;
  letter-spacing: 1px;
}
.shops {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.shop {
  width: 47%;
  height: 230px;
  margin: 0.1rem;
  padding: 0 1rem;
  border: 1px solid #333;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.shop-chosen {
  /* background-color: rgba(251, 99, 34, 0.4); */
  background-color: rgba(241, 241, 63, 0.4);
}
.adds {
  padding: 0 1rem;
}
.shop-name {
  font-size: 25px;
  font-weight: 600;
  letter-spacing: 1px;
}
.address-en {
  font-size: 20px;
  font-weight: 600;
}

@media (max-width: 670px) {
  .shop {
    width: 100%;
    max-height: 250px;
  }
}
