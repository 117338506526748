/* ---top image slide--- */
.hero {
  position: relative;
}
/* .album {
} */
.album img {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}
.album .img1 {
  display: block;
  margin: 0 auto;
}
.album .img2,
.album .img3,
.album .img4,
.album .img5 {
  position: absolute;
  top: 0;
  left: 0;
}

.album img {
  animation-name: album;
  animation-duration: 20s;
  animation-iteration-count: infinite;
  opacity: 0;
  box-shadow: 0px 0px 20px -5px #fff;
}
.album .img1 {
  animation-delay: 0s;
}
.album .img2 {
  animation-delay: 5s;
}
.album .img3 {
  animation-delay: 10s;
}
.album .img4 {
  animation-delay: 15s;
}
@keyframes album {
  0% {
    opacity: 0;
  }
  6.5% {
    opacity: 1;
  }
  18.5% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
}
@-webkit-keyframes album {
  0% {
    opacity: 0;
  }
  6.5% {
    opacity: 1;
  }
  18.5% {
    opacity: 1;
  }
  25% {
    opacity: 0;
  }
}

.logo {
  width: auto;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  text-align: center;
  font-size: 5rem;
  font-weight: bold;
  color: #fff;
  text-shadow: 2px 3px 4px #778899;
  letter-spacing: 7px;
}
/* ---top image slide--- */

@media (max-width: 780px) {
  .album img {
    width: 100%;
    height: 80vh;
    object-fit: cover;
  }
  .logo {
    font-size: 3rem;
  }
}
