* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  font-family: "M PLUS 1p", sans-serif;
  color: #333;
}
li {
  list-style: none;
  width: auto;
}
a {
  text-decoration: none;
  color: #cd5c5c;
}
img {
  vertical-align: bottom;
}

.App {
  width: 100vw;
}
